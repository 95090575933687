<template>
    <div>
        <v-row>
            <v-col :cols="cols" class="py-1"> Library version </v-col>
            <v-col class="py-1">
                {{ version }}
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="cols" class="py-1"> Created by </v-col>
            <v-col class="py-1">
                {{ createdBy }}
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="cols" class="py-1"> Last updated </v-col>
            <v-col class="py-1">
                {{ lastUpdate }}
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="cols" class="py-1"> Calc ID </v-col>
            <v-col class="py-1">
                {{ calcId }}
            </v-col>
        </v-row>
        <v-row class="py-1" v-if="persistentCalcId">
            <v-col :cols="cols" class="py-1"> Persistent Calc ID </v-col>
            <v-col class="py-1">
                {{ persistentCalcId }}
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: ['version', 'createdBy', 'lastUpdate', 'calcId', 'persistentCalcId'],
    data() {
        return {
            cols: 3,
        }
    },
}
</script>
