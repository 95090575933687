<template>
    <v-hover v-slot="{ hover }">
        <v-card
            :class="{ 'on-hover': hover, 'pa-4': true }"
            elevation="0"
            :style="{
                'background-color':
                    hover || selectedRecord ? '#e0e0e0' : '#ffffff',
                width: '100%',
            }"
        >
            <v-row style="width: 100%" class="ma-0">
                <v-col cols="9" v-if="record">
                    <div class="text-caption">{{ dateString }}</div>
                    <div class="d-flex my-2">
                        <EWR
                            v-if="output.errors"
                            icon="mdi-alert-octagon"
                            color="#ff5252"
                            :number="output.errors.length"
                            text="error"
                        />
                        <EWR
                            v-if="output.warnings"
                            class="ml-2"
                            icon="mdi-alert"
                            color="#ffc107"
                            :number="output.warnings.length"
                            text="warning"
                        />
                        <EWR
                            v-if="output.remarks"
                            class="ml-2"
                            icon="mdi-information"
                            color="#2196f3"
                            :number="output.remarks.length"
                            text="remark"
                        />
                    </div>
                    <v-row style="width: 100%">
                        <v-col sm="2" md="1" class="d-flex align-center"
                            ><b>Inputs</b></v-col
                        >
                        <v-col
                            sm="10"
                            md="11"
                            style="
                                width: 100%;
                                text-overflow: ellipsis;
                                overflow-wrap: anywhere;
                                word-break: break-all;
                                overflow-x: hidden;
                            "
                        >
                            {{ inputsString }}
                        </v-col>
                    </v-row>
                    <v-row style="width: 100%">
                        <v-col sm="2" md="1" class="d-flex align-center"
                            ><b>Outputs</b></v-col
                        >
                        <v-col
                            sm="10"
                            md="11"
                            style="
                                text-overflow: ellipsis;
                                overflow-wrap: anywhere;
                                word-break: break-all;
                                overflow: hidden;
                            "
                        >
                            <div
                                v-katex:auto="{ options: katexOptions }"
                                v-if="record.isSingleResults"
                                v-html="record.output.result"
                            ></div>
                            <div
                                v-else
                                v-katex:auto="{ options: katexOptions }"
                            >
                                {{ resultString }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3" class="d-flex align-center">
                    <RecordButtons
                        :record="record"
                        @restore="restore"
                        @share="() => (shareDialog = true)"
                    />
                </v-col>
                <ShareDialog :record="record" v-model="shareDialog" />
            </v-row>
        </v-card>
    </v-hover>
</template>
<script>
import EWR from './EWR.vue'
import ShareDialog from './ShareDialog.vue'
import RecordButtons from './RecordButtons.vue'

export default {
    props: ['record', 'selectedRecord'],
    components: { EWR, ShareDialog, RecordButtons },
    data() {
        return {
            shareDialog: false,
            pdfDownload: false,
            /**
             * Options how katex math is displayed
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
        }
    },
    methods: {
        restore() {
            this.$emit('restore', this.record)
        },
        /**
         * Stringify the result
         */
        stringifyResult(html, units, symbol, value) {
            if (html != null) return html
            else {
                let u = units ? ' ' + units : ''
                return symbol + ' = ' + value + u
            }
        },
    },
    computed: {
        inputsString() {
            // probably best to write my own parser here, using the "body" property rather than the "query" property
            return this.record.query
                .replace('?', '')
                .replaceAll('%20', ' ')
                .replaceAll('%5B', '[')
                .replaceAll('%5D', ']')
                .split('&')
                .join(', ')
        },
        dateString() {
            return new Date(this.record.date).toLocaleString()
        },
        output() {
            return this.record.output
        },
        /**
         * Result string if not showing calc info
         */
        resultString() {
            var short = []
            if (!this.record.output.arupComputeResultItems) return ''
            for (var r in this.record.output.arupComputeResultItems) {
                let results = this.record.output.arupComputeResultItems
                //is object
                // eslint-disable-next-line no-prototype-builtins
                if (results.hasOwnProperty(r)) {
                    short.push(
                        this.stringifyResult(
                            results[r].html,
                            results[r].units,
                            results[r].symbol,
                            results[r].value
                        )
                    )
                }
                //is array
                else {
                    short.push(
                        this.stringifyResult(r.html, r.units, r.symbol, r.value)
                    )
                }
            }

            return short.join(', ')
        },
    },
}
</script>
